export const Cookies = () => import('../../components/cookies.vue' /* webpackChunkName: "components/cookies" */).then(c => wrapFunctional(c.default || c))
export const Features = () => import('../../components/features.vue' /* webpackChunkName: "components/features" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const GoogleMapsMarker = () => import('../../components/google-maps-marker.vue' /* webpackChunkName: "components/google-maps-marker" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../components/map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const ReservationBlock = () => import('../../components/reservation-block.vue' /* webpackChunkName: "components/reservation-block" */).then(c => wrapFunctional(c.default || c))
export const ServiceRow = () => import('../../components/service-row.vue' /* webpackChunkName: "components/service-row" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
